
  import { Component, Vue } from 'vue-property-decorator';
  import axios from 'axios';
  import { AuthenticationService } from '../../services/authentication.service';
  import ArticleEditor from '@/components/articles/ArticleEditor.component.vue';
  import { Article } from '@/interfaces/arcticles/Article.entity';
  import * as Consts from '@/scripts/Consts';

/**
 * @desc page de création d'un article en tant qu'enseignant / administrateur
 */
  @Component({
    components: {
      'article-editor' : ArticleEditor,
    },
  })
  export default class CreateArticleAdmin extends Vue {
    article : Article = new Article();

    mounted() : void {
      if(AuthenticationService.getUserStatus() !== Consts.user_status_teacher){
        this.$router.push('/');
        return;
      }
    }

    /**
     * @desc charge un article à modifier (éventuellement un brouillon)
     */
    loadDraftArticle(article : Article) : void {
      this.article = article;
    }

    deletedDraftArticle(article : Article): void {
      if(article.id === this.article.id) {
        this.article = new Article();
      }
    }
  }
